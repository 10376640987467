body {
  font-family: "Courier New", Courier, monospace;
}

a {
  color: rgb(196, 194, 111);
  font-weight: bold;
}

.header {
  display: flex;
  align-items: center;
  padding: 30px;
  background-color: #fff;

  margin-bottom: 30px;
}

.title {
  font-size: 40px;
}

.subtitle {
  font-size: 15px;
  font-style: italic;
}

.container {
  padding: 20px;
}

.footer {
  display: flex;
  justify-content: center;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-card {
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 10px 10px 0 0;
}

.contact-link {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.icon {
  margin-right: 10px;
  width: 25px;
}