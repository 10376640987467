.card {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px 0px,
    rgba(0, 0, 0, 0.07) 0px 2px 4px 0px;
}

.card.app {
  flex-direction: column;
  margin-bottom: 50px;
  max-width: 500px;
}

.card.policy {
  padding: 50px;
  background-color: #fff;
}

.icon-container {
  border-radius: 10px 10px 0 0;
  position: relative;
}

.icon-container .icon {
  margin-bottom: 20px;
  width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.icon-container .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  transition-duration: 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-container .overlay:hover {
  background-color: rgba(0,0,0,0.4);
}

.icon-container .overlay .external-link {
  cursor: pointer;
  color: white;
  background-color: black;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  font-weight: bold;
  text-align: center;

  transition-duration: 0.5s;
  opacity: 0;
}

.icon-container .overlay:hover .external-link {
  opacity: 1;
}

@media (max-width : 600px) {
  .icon-container .overlay {
    background-color: rgba(0,0,0,0.4);
  }

  .icon-container .overlay .external-link {
    opacity: 1;
  }
}

.collaboration-container {
  position: absolute;
  padding: 10px;
  bottom: 0;
}

.collaboration-container a {
  color: white;
  font-weight: bold;
}

.links {
  background-color: #fff;
  border-top: 1px solid #aaa;
  border-radius: 0 0 10px 10px;
  text-align: center;
}

.storeLinks {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 30px;
}

.storeButton {
  flex: 1;
  max-width: 50%;
}

.storeButton a {
  display: inline-block;
}

.storeButton img {
  width: 100%;
  max-width: 100%;
}

.storeButton .iosLink {
  padding: 6%;
  width: 79%;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.policyLink {
  padding-bottom: 20px;
}
